import React, { useState, Fragment } from "react"
import axios from "axios"

import SubscribeStyle from "./subscribe.module.css"
import SubscribeContent from "./SubscribeContent"
import SetSubscriptionPopupCookie from "../../utils/SetSubscriptionPopupCookie"

const Subscribe = () => {
  const [email, setEmail] = useState("")

  const failedStatus = -1
  const initStatus = 0
  const awatingStatus = 1
  const successStatus = 2
  const [responseStatus, setResponseStatus] = useState(initStatus)

  const resetResponseStatus = () => {
    setTimeout(() => {
      setEmail("")
      setResponseStatus(initStatus)
    }, 1000)
  }
  const handleSubmit = event => {
    setResponseStatus(awatingStatus)
    event.preventDefault()

    let data = new FormData()
    data.append("email", email)

    axios
      .get(
        `https://script.google.com/macros/s/AKfycbzCiNjCKCShKr6vnkleP1BdJuvJCo-uJIfKgF-N7kJXrvApj7U/exec?email=${email}`
      )
      .then(function(response) {
        if (response.data.status === "success") {
          setResponseStatus(successStatus)
          SetSubscriptionPopupCookie()
        } else {
          setResponseStatus(failedStatus)
          resetResponseStatus()
        }
      })
      .catch(function(error) {
        setResponseStatus(failedStatus)
        resetResponseStatus()
      })
  }

  return (
    <div className={SubscribeStyle.subscribe}>
      <p className={SubscribeStyle.header}>
        {responseStatus === successStatus
          ? "Thank You For Subscribing!"
          : SubscribeContent.title}
      </p>

      {responseStatus !== successStatus && (
        <Fragment>
          <p
            className={SubscribeStyle.description}
            dangerouslySetInnerHTML={{ __html: SubscribeContent.description }}
          />
          <form onSubmit={handleSubmit}>
            <input
              className="mb-30"
              type="email"
              name="email"
              placeholder="E-mail"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
            <button type="submit" disabled={responseStatus !== initStatus}>
              {responseStatus === initStatus && "Subscribe"}
              {responseStatus === awatingStatus && "SENDING..."}
              {responseStatus === failedStatus && "Failed"}
            </button>
          </form>
        </Fragment>
      )}
    </div>
  )
}

export default Subscribe
