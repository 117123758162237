import React, { useState } from "react"

const Img = ({ sources, fallback, ...delegated }) => {
  const [dimesions, setDimesions] = useState({
    width: "100%",
    height: "auto",
  })
  const onImgLoad = ({ target: img }) => {
    setDimesions({
      height: img.offsetHeight,
      width: img.offsetWidth,
    })
  }

  return (
    <picture>
      {sources.map(({ src, type, ...props }, i) => (
        <source key={i} srcSet={src} type={type} {...props} />
      ))}
      <img
        src={fallback}
        onLoad={onImgLoad}
        width={dimesions.width}
        height={dimesions.height}
        {...delegated}
      />
    </picture>
  )
}

export default Img
