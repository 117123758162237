import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/Footer"
import { Link } from "gatsby"
import Subscribe from "../components/Subscribe"
import Img from "../components/Img"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1 className="hidden">
      Manastır - A Design-Oriented Integrated Solution Agency
    </h1>
    <div className="row">
      <div className="col-12">
        <video playsInline autoPlay muted loop width="100%">
          <source
            src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/manastir-ys.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
    <div className="" style={{ marginBottom: `20px`, marginTop: `90px` }}>
      <span className="light_text">our</span>{" "}
      <span className="bold_text">works</span>
    </div>
    <div className="row">
      <div className="col-4 col-sm">
        <Link to="/works/cekerek-nature-and-sports-festival">
          <div className="work">
            <img style={{ height: '450px', objectFit: 'cover' }}
                 src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/NSF-6.jpg"
                 srcSet={`${"https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/NSF-6.jpg"} 1x, ${"https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/NSF-6.jpg"} 2x`}
                alt="ÇEKEREK NATURE AND SPORTS FESTIVAL"
            />

            <div className="overlay">
              <div className="overlay_info_work">
                <div className="work_title">
                  ÇEKEREK NATURE AND SPORTS FESTIVAL
                </div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-8 col-sm">
        <Link to="/works/turkey-maritime-summit">
          <div className="work">
            <img style={{ height: '450px', objectFit: 'cover' }}
                src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/TMS-1.jpg"
                srcSet={`${"https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/TMS-1.jpg"} 1x, ${"https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/TMS-1.jpg"} 2x`}
                alt="SUMMIT 23"
            />

            <div className="overlay">
              <div className="overlay_info_work">
                <div className="work_title">
                  TURKEY MARITIME SUMMIT
                </div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
    <div className="row">
      <div className="col-8 col-sm">
        <Link to="/works/summit-23">
          <div className="work">
            <img
                src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/STA_8.jpg"
                srcSet={`${"https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/STA_8.jpg"} 1x, ${"https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/STA_8.jpg"} 2x`}
                alt="SUMMIT 23"
            />

            <div className="overlay">
              <div className="overlay_info_work">
                <div className="work_title">
                  SUMMIT 23
                </div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-4 col-sm">
        <Link to="/works/yildiz-teknik-university-spring-fest" style={{ display: 'flex', height: '100%' }}>
          <div className="work">
            <img style={{ height: '100%', objectFit: 'cover' }}
                src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/YTU-5.jpg"
                srcSet={`${"https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/YTU-5.jpg"} 1x, ${"https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/YTU-5.jpg"} 2x`}
                alt="YILDIZ TECHNICAL UNIVERSITY SPRING FEST"
            />

            <div className="overlay">
              <div className="overlay_info_work">
                <div className="work_title">
                  YILDIZ TECHNICAL UNIVERSITY SPRING FEST
                </div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
    <div className="row">
      <div className="col-4 col-sm">
        <Link to="/works/satellite-technologies-week">
          <div className="work">
            <img
              src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/UTH.png"
              srcSet={`${"https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/UTH.png"} 1x, ${"https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/UTH@2x.png"} 2x`}
              alt="SATELLITE TECHNOLOGIES WEEK"
            />
            <div className="overlay">
              <div className="overlay_info_work">
                <div className="work_title">SATELLITE TECHNOLOGIES WEEK</div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-8 col-sm">
        <Link to="/works/turkish-railways-summit">
          <div className="work">
            <img
              src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/TCDD.png"
              srcSet={`${"https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/TCDD.png"} 1x, ${"https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/TCDD@2x.png"} 2x`}
              alt="TURKISH RAILWAYS SUMMIT"
            />

            <div className="overlay">
              <div className="overlay_info_work">
                <div className="work_title">TURKISH RAILWAYS SUMMIT</div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
    <div className="row">
      <div className="col-8 col-sm">
        <Link to="/works/tusas">
          <div className="work">
            <img
              src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/TUSAS.png"
              srcSet={`${"https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/TUSAS.png"} 1x, ${"https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/TUSAS@2x.png"} 2x`}
              alt=" TUSAS INTERNAL COMMUNICATIONS AND EMPLOYER BRANDING"
            />

            <div className="overlay">
              <div className="overlay_info_work">
                <div className="work_title">
                  TUSAS INTERNAL COMMUNICATIONS AND EMPLOYER BRANDING
                </div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-4 col-sm">
        <Link to="/works/bias-in-artificial-intelligence">
          <div className="work">
            <img
              src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/BIAS.png"
              srcSet={`${"https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/BIAS.png"} 1x, ${"https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/BIAS@2x.png"} 2x`}
              alt="BIAS IN ARTIFICIAL INTELLIGENCE"
            />

            <div className="overlay">
              <div className="overlay_info_work">
                <div className="work_title">
                  BIAS IN ARTIFICIAL INTELLIGENCE
                </div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
    <div className="row">
      <div className="col-4 col-sm">
        <Link to="/works/fifteen-seconds-festival">
          <div className="work">
            <Img
              sources={[
                {
                  src:
                    "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/01-FS.webp",
                  type: "image/webp",
                },
              ]}
              fallback="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/01-FS.jpg"
              alt="FIFTEEN SECONDS ISTANBUL - Manastır part of moodley Design Group - moodley İstanbul"
            />

            <div className="overlay">
              <div className="overlay_info_work">
                <div className="work_title">FIFTEEN SECONDS ISTANBUL</div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Link>
      </div>

      <div className="col-4 col-sm">
        <Link to="/works/transist">
          <div className="work">
            <Img
              sources={[
                {
                  src:
                    "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/02-TRANSIST.webp",
                  type: "image/webp",
                  media: "(max-width:768px)",
                },
                {
                  src:
                    "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/02-TRANSIST.webp 1x, https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/02-TRANSIST@2x.webp 2x",
                  type: "image/webp",
                },
                {
                  src:
                    "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/02-TRANSIST.jpg 1x, https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/02-TRANSIST@2x.jpg 2x",
                },
              ]}
              fallback="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/02-TRANSIST.jpg"
              alt="TRANSIST - Manastır part of moodley Design Group - moodley İstanbul"
            />

            <div className="overlay">
              <div className="overlay_info_work">
                <div className="work_title">TRANSIST</div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Link>
      </div>

      <div className="col-4 col-sm">
        <Link to="/works/young-turkey-summit">
          <div className="work">
            <Img
              sources={[
                {
                  src:
                    "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/03-GTZ.webp",
                  type: "image/webp",
                  media: "(max-width:768px)",
                },
                {
                  src:
                    "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/03-GTZ.webp 1x, https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/03-GTZ@2x.webp 2x",
                  type: "image/webp",
                },
                {
                  src:
                    "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/03-GTZ.jpg 1x, https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/03-GTZ@2x.jpg 2x",
                },
              ]}
              fallback="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/03-GTZ.jpg"
              alt="YOUNG TURKEY SUMMIT - Manastır part of moodley Design Group - moodley İstanbul"
            />

            <div className="overlay">
              <div className="overlay_info_work">
                <div className="work_title">YOUNG TURKEY SUMMIT</div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
    <div className="row">
      <div className="col-8 col-sm">
        <Link to="/works/istanbul-design-summit">
          <div className="work">
            <Img
              sources={[
                {
                  src:
                    "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/04-IDS.webp",
                  type: "image/webp",
                  media: "(max-width:768px)",
                },
                {
                  src:
                    "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/04-IDS.webp 1x, https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/04-IDS@2x.webp 2x",
                  type: "image/webp",
                },
                {
                  src:
                    "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/04-IDS.jpg 1x, https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/04-IDS@2x.jpg 2x",
                },
              ]}
              fallback="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/04-IDS.jpg"
              alt="ISTANBUL DESIGN SUMMIT - Manastır part of moodley Design Group - moodley İstanbul"
            />

            <div className="overlay">
              <div className="overlay_info_work">
                <div className="work_title">ISTANBUL DESIGN SUMMIT</div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-4 col-sm">
        <div className="row">
          <div className="col-12">
            <Link to="/works/goodmarked">
              <div className="work">
                <Img
                  sources={[
                    {
                      src:
                        "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/03-GOODMARKED.webp",
                      type: "image/webp",
                      media: "(max-width:768px)",
                    },
                    {
                      src:
                        "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/03-GOODMARKED.webp 1x, https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/03-GOODMARKED@2x.webp 2x",
                      type: "image/webp",
                    },
                    {
                      src:
                        "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/03-GOODMARKED.jpg 1x, https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/03-GOODMARKED@2x.jpg 2x",
                    },
                  ]}
                  fallback="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/03-GOODMARKED.jpg"
                  alt="GOODMARKED - Manastır part of moodley Design Group - moodley İstanbul"
                />

                <div className="overlay">
                  <div className="overlay_info_work">
                    <div className="work_title">GOODMARKED</div>
                    <div className="line"></div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm">
            <Link to="/works/istanbul-craft-week">
              <div className="work">
                <Img
                  sources={[
                    {
                      src:
                        "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/06-CRAFT.webp",
                      type: "image/webp",
                      media: "(max-width:768px)",
                    },
                    {
                      src:
                        "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/06-CRAFT.webp 1x, https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/06-CRAFT@2x.webp 2x",
                      type: "image/webp",
                    },
                    {
                      src:
                        "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/06-CRAFT.jpg 1x, https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/06-CRAFT@2x.jpg 2x",
                    },
                  ]}
                  fallback="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/06-CRAFT.jpg"
                  alt="ISTANBUL CRAFT WEEK - Manastır part of moodley Design Group - moodley İstanbul"
                />

                <div className="overlay">
                  <div className="overlay_info_work">
                    <div className="work_title">ISTANBUL CRAFT WEEK</div>
                    <div className="line"></div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-4 col-sm">
        <Link to="/works/inport">
          <div className="work">
            <Img
              sources={[
                {
                  src:
                    "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/07-INPORT.webp",
                  type: "image/webp",
                  media: "(max-width:768px)",
                },
                {
                  src:
                    "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/07-INPORT.webp 1x, https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/07-INPORT@2x.webp 2x",
                  type: "image/webp",
                },
                {
                  src:
                    "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/07-INPORT.jpg 1x, https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/07-INPORT@2x.jpg 2x",
                },
              ]}
              fallback="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/07-INPORT.jpg"
              alt="INPORT - Manastır part of moodley Design Group - moodley İstanbul"
            />

            <div className="overlay">
              <div className="overlay_info_work">
                <div className="work_title">INPORT</div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-8 col-sm">
        <Link to="/works/audito">
          <div className="work">
            <Img
              sources={[
                {
                  src:
                    "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/08-NIC-ISTANBUL.webp",
                  type: "image/webp",
                  media: "(max-width:768px)",
                },
                {
                  src:
                    "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/08-NIC-ISTANBUL.webp 1x, https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/08-NIC-ISTANBUL@2x.webp 2x",
                  type: "image/webp",
                },
                {
                  src:
                    "https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/08-NIC-ISTANBUL.jpg 1x, https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/08-NIC-ISTANBUL@2x.jpg 2x",
                },
              ]}
              fallback="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/08-NIC-ISTANBUL.jpg"
              alt="AUDITO - Manastır part of moodley Design Group - moodley İstanbul"
            />

            <div className="overlay">
              <div className="overlay_info_work">
                <div className="work_title">AUDITO</div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
    <Subscribe />
    <Footer />
  </Layout>
)

export default IndexPage
